/*Tabs.js*/
import React, { useState } from "react";
import RunderTeppich from "../../pages/runderTeppich";
import RechteckigerTeppich from "../../pages/rechteckigerTeppich";
import RunderKorb from "../../pages/runderKorb";

const Tabs = () => {
  // Zustand für den aktuell ausgewählten Tab
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="tabs">
      {/* Tab-Überschriften */}
      <div className="flex">
        <div
          className={` text-lg  text-textiloFont tab cursor-pointer p-4 ${
            activeTab === 1 ? "bg-gray-100 border-b-2 border-textiloFont" : ""
          }`}
          onClick={() => setActiveTab(1)}
        >
          Rund
        </div>
        <div
          className={`text-lg  text-textiloFont tab cursor-pointer p-4  ${
            activeTab === 2 ? "bg-gray-100  border-b-2 border-textiloFont" : ""
          }`}
          onClick={() => setActiveTab(2)}
        >
          Rechteckig
        </div>
      </div>

      {/* Tab-Inhalte */}
      <div className="tab-content">
        {activeTab === 1 && (
          <div>
            <h2 className="text-textiloSeaDark text-2xl text-left my-4">
              Runde Teppiche & Projekte
            </h2>
            <RunderTeppich />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <h2 className="text-textiloSeaDark text-2xl text-left my-4">
              Decken , Tischstets, Teppiche ...
            </h2>
            <RechteckigerTeppich />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <h2 className="text-textiloSeaDark text-2xl text-left my-4">
              Runder Korb
            </h2>
            <RunderKorb />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
