import React from "react";

const Logo = () => {
  return (
    <main>
      <h1 className="text-3xl font-bold text-right pr-1 text-textiloSeaDark mt-5 mb-0">
        Textilo-Shop Materialrechner
      </h1>
      <h2 className="font-bold text-textiloSeaDark text-3xl pr-1 text-right mt-[-25]">
        Teppiche, Decken
      </h2>

      <hr className="m-1 mb-3 border-b-2 border-textiloSeaDark"></hr>
    </main>
  );
};

export default Logo;
